import styled from 'styled-components';

type ListItemStyleTypeProps = {
    marker: 'disc' | 'circle' | 'square' | 'none';
}

const ListItem = styled.li<ListItemStyleTypeProps>`
  list-style-type: ${({marker}) => marker};
`
export default ListItem;


