import React, {FC} from 'react';
import StyledFooter from '../styles/Footer';
import Container from '../styles/components/Container';
import {ReactComponent as LogoWhite} from '../assets/main/logo-white.svg'
import {Link} from 'react-router-dom';

const Footer: FC = () => {
    return (
        <StyledFooter.Wrap>
            <Container>
                <StyledFooter.Container>
                    <StyledFooter.Contacts>
                        <StyledFooter.ContactsCell>
                            <Link to='/'>
                                <StyledFooter.Logo>
                                    <LogoWhite/>
                                </StyledFooter.Logo>
                            </Link>
                        </StyledFooter.ContactsCell>
                        <StyledFooter.ContactsCell>
                            <StyledFooter.Contact>
                                <h6>Адрес студии</h6>
                                <div>Метро Дубровка. ул. 1 машиностроения, д. 10, Студия 315</div>
                            </StyledFooter.Contact>
                            <StyledFooter.Contact>
                                <h6>Instagram:</h6>
                                <StyledFooter.RouterLink to='https://www.instagram.com/iskusstvo_boksa/'>
                                    «Искусство Бокса»
                                </StyledFooter.RouterLink>
                            </StyledFooter.Contact>
                        </StyledFooter.ContactsCell>
                        <StyledFooter.ContactsCell>
                            <StyledFooter.Contact>
                                <h6>Телефон</h6>
                                <StyledFooter.Link className='footer__link' href='tel:+79999797931' title='Позвонить'>
                                    +7 (999) 979-79-31
                                </StyledFooter.Link>
                            </StyledFooter.Contact>
                            <StyledFooter.Contact>
                                <h6>Email</h6>
                                <StyledFooter.Link
                                    className='footer__link'
                                    href='mailto:artboxing@bk.ru' title='Написать'
                                >
                                    artboxing@bk.ru
                                </StyledFooter.Link>
                            </StyledFooter.Contact>
                        </StyledFooter.ContactsCell>
                    </StyledFooter.Contacts>
                    <StyledFooter.Copyrights>
                        <StyledFooter.CopyrightItem>©</StyledFooter.CopyrightItem>
                        <StyledFooter.CopyrightItem>
                            <StyledFooter.Link className='footer__link' href='/' title='Искусство бокса'>
                                Искусство бокса
                            </StyledFooter.Link>,
                            2022
                        </StyledFooter.CopyrightItem>
                        <StyledFooter.CopyrightItem>
                            <StyledFooter.Link className='footer__link' href='https://cetera.ru/' title='cetera.ru'>
                                Создание сайта
                            </StyledFooter.Link>
                            <span> — Erpico & Cetera Labs</span>
                        </StyledFooter.CopyrightItem>
                        <StyledFooter.CopyrightItem>
                            <StyledFooter.Link
                                className='footer__link'
                                title='Сообщить об ошибке'
                                href={'https://www.cetera.ru/support/default.php'
                                    +
                                    '?project=IPVD&amp;lang=ru&amp;page=//artboxing.ru/coaches/'}
                            >
                                Сообщить об ошибке
                            </StyledFooter.Link>
                        </StyledFooter.CopyrightItem>
                        <StyledFooter.CopyrightItem>
                            <StyledFooter.RouterLink
                                className='footer__link'
                                title='Политика конфиденциальности'
                                to='/privacy_statement'
                            >
                                Политика конфиденциальности
                            </StyledFooter.RouterLink>
                        </StyledFooter.CopyrightItem>
                        <StyledFooter.CopyrightItem>
                            <StyledFooter.RouterLink className='footer__link' title='Карта сайта' to='/sitemap/'>
                                Карта сайта
                            </StyledFooter.RouterLink>
                        </StyledFooter.CopyrightItem>
                    </StyledFooter.Copyrights>
                </StyledFooter.Container>
            </Container>
        </StyledFooter.Wrap>
    );
};

export default Footer;
