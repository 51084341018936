import styled from 'styled-components';
import {Link} from 'react-router-dom';

const StyledFooter = {
    Wrap: styled.footer`
      padding: 2.625rem 0 1.3125rem;
      background-color: #222;
      color: #fff;
      font-size: .875rem;
    `,
    Container: styled.div`
      padding-right: 0.625rem;
      padding-left: 0.625rem;
    `,
    Contacts: styled.div`
      margin-bottom: 0.9375rem;
      display: flex;
      flex-flow: row wrap;
    `,
    ContactsCell: styled.div`
      width: 25%;
      padding-right: 0.625rem;
      padding-left: 0.625rem;

      @media (max-width: 960px) {
        width: 50%;
      }

      @media (max-width: 640px) {
        width: 100%;
      }
    `,
    Logo: styled.div`
      transform: translateY(-1.2rem);

      svg {
        width: 100%;
        max-width: 15rem;
      }
    `,
    Contact: styled.div`
      margin-bottom: 0.625rem;
    `,
    Copyrights: styled.ul`
      margin: 0;
      list-style-type: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 0.9rem;
    `,
    CopyrightItem: styled.li`
      margin-right: 0.625rem;
    `,
    Link: styled.a`
      color: #8c8c8c;

      &:hover {
        color: #7c9baa;
      }
    `,
    RouterLink: styled(Link)`
      color: #8c8c8c;

      &:hover {
        color: #7c9baa;
      }
    `
}

export default StyledFooter