import StyleProfile from '../../styles/StyleProfile';
import SwiperComponent from '../../components/SwiperComponent';
import {useState, useEffect, FC} from 'react';
import {PackType} from '../../types';
import {useGetPacksQuery} from '../../services/PacksService';
import {dateToPeopleView, translateStatus} from '../../utils';
import {RootState} from '../../redux/store';
import {useSelector} from 'react-redux';

const ProfilePacks: FC = () => {
    const {token} = useSelector((state: RootState) => state.auth)
    const {data: packsInfo} = useGetPacksQuery({id: 138, token})

    const [packs, setPacks] = useState<JSX.Element[]>([
        <StyleProfile.Item center={true}>
            <div>Здесь будут отображаться ваши абонементы</div>
        </StyleProfile.Item>
    ]);

    useEffect(() => {
        if (packsInfo?.length) {
            const packsForSet = packsInfo.filter((pack: PackType) => ['active', 'waiting'].includes(pack.state))

            if (packsForSet.length) {
                setPacks(packsForSet
                    .map((pack: PackType, index: number) =>
                        <StyleProfile.Item key={index}>
                            <div>{pack.packname}</div>
                            <div>Осталось тренировок: {pack.left}/{pack.amount}</div>
                            <div>Истекает: {dateToPeopleView(pack.expire)}</div>
                            <div>Статус: {translateStatus(pack.state)}</div>
                        </StyleProfile.Item>
                    )
                )
            }
        }
    }, [packsInfo]);

    return (
        <StyleProfile.Packs>
            <SwiperComponent
                slides={packs}
                arrowWidth={20}
            />
        </StyleProfile.Packs>
    )
}

export default ProfilePacks