import styled from 'styled-components';

const StyledProducts = {
    Wrap: styled.div``,
    Product: styled.div`
        border: 1px solid #d5d7d8;
        border-radius: 8px;
        padding: 12px;
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        column-gap: 30px;
    `,
    Column: styled.div``,
    ProductName: styled.div`
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
    `,
    ProductDescription: styled.div`
        margin: 5px 0;
    `,
    ProductCost: styled.div`
          margin: 5px 0;
          text-align: center;
    `,
}

export default StyledProducts