import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const Paginate = styled(ReactPaginate).attrs({
    containerClassName: 'pagination-articles',
    previousLinkClassName: 'page-num',
    pageLinkClassName: 'page-num',
    nextLinkClassName: 'page-num',
})`
    display: flex;
    margin-top: 1.5rem;
    margin-left: 0;
    margin-bottom: 0.25rem;
  
    li {
      padding: 0.1875rem 0.625rem;
      cursor: pointer;
      transition: color 200ms ease-in-out;
      font-size: .875rem;

      &:not(:first-child):not(:last-child) {
        @media (max-width: 546px) {
          display: none;
        }
      }
    }

    .disabled {
      color: #cacaca;
      cursor: not-allowed;
    }
    
    li.selected:not(.init) {
      background-color: #000000;
      border-radius: 0.25rem;
      color: #ffffff;
      cursor: default;
    }

    li.selected a:hover {
        color: #ffffff;
    }

  .previous {
    &:not(.disabled) {
      .page-num:hover {
        background-color: #E6E6E6;
        transform: scale(0);
      }
    }
    .page-num {
      border-radius: 0.25rem;
      padding: 0.1875rem 0.625rem;
    }
  }

  .next {
    &:not(.disabled) {
      .page-num:hover {
        background-color: #E6E6E6;
        padding: 0.5rem 0.5rem;
        transform: scale(0);
      }
    }
    .page-num {
      border-radius: 0.25rem;
      padding: 0.1875rem 0.625rem;
    }
  }
  
`;

export default Paginate
