import React, {FC} from 'react';
import {RootState, useAppDispatch} from '../redux/store';
import {toggleMobMenu} from '../redux/slices/uiSlice';
import {useSelector} from 'react-redux';
import { BurgerButton, BurgerLine } from '../styles/Burger';

const Burger: FC = () => {
    const dispatch = useAppDispatch()
    const {mobMenuOpen} = useSelector((state: RootState) => state.ui)

    return (
        <BurgerButton open={mobMenuOpen} onClick={() => dispatch(toggleMobMenu())}>
            <BurgerLine top/>
            <BurgerLine/>
            <BurgerLine bottom/>
        </BurgerButton>
    );
};

export default Burger;
