import React, {FC, useEffect, useState} from 'react';
import {ArticleCard, Breadcrumbs} from '../components';
import {useLocation, useNavigate} from 'react-router-dom';
import {ArticleType, PromoType} from '../types';
import {ArticlePage} from '../styles/components';
import auctionsJSON from '../assets/auctions.json';
import useDocumentTitle from '../utils/useDocumentTitle';

const Auction: FC = () => {
    const [promos, setPromos] = useState<Array<PromoType>>([]);
    const location = useLocation();
    const navigate = useNavigate();

    useDocumentTitle('Промо-акции | Искусство бокса', true);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const data = auctionsJSON as Array<PromoType>;
            setPromos(data)
        }
        return () => {
            mounted = false;
        };
    }, []);

    const handleClick = (href: string) => {
        navigate(href);
    }

    return (
        <>
            <Breadcrumbs
                location={location}
                title='Промо-акции'
            />
            <h1>Промо-акции</h1>
            <ArticlePage>
                {promos.map((item: ArticleType) => (
                    <ArticleCard
                        key={item.id}
                        imageURL={require(`../assets/auction${item.imageUrl}`)}
                        title={item.title}
                        onClick={() => handleClick(item.href)}
                        href={item.href}
                        withButton={true}
                    />
                ))}
            </ArticlePage>
        </>
    );
};

export default Auction;
