import React, {FC, useEffect, useState} from 'react';
import {Breadcrumbs, TrainerCard, TrainerCardLoader} from '../components';
import {useLocation, useNavigate} from 'react-router-dom';
import useDocumentTitle from '../utils/useDocumentTitle';
import { useGetAllTrainersQuery } from '../services/TrainersServices';
import { ArticlePage } from '../styles/components';
import { AllTrainersResponseType } from '../types';

const Coaches: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [trainers, setTrainers] = useState<Array<AllTrainersResponseType>>([]);
    const {data = [], isLoading, error} = useGetAllTrainersQuery(138);

    useDocumentTitle('Тренеры | Искусство бокса', true);


    useEffect(() => {
        if (data) {
            setTrainers(data as AllTrainersResponseType[]);
        }
    }, [data])


    const showInfo = (href: string) => {
        navigate(href);
    }


    return (
        <>
            <Breadcrumbs
                location={location}
                title='Тренеры'
            />
            <h1>Тренеры</h1>
            {error ? (
                <div>
                    <h5>Произошла ошибка при получении списка тренеров. Попробуйте повторить попытку позже</h5>
                </div>
            ) : (
                <ArticlePage>
                    {
                        isLoading ? [...new Array(6)].map((_, index) => <TrainerCardLoader key={index}/>)
                            : trainers.map((trainer) => (
                               <TrainerCard
                                key={trainer.id}
                                href={`/coaches/${trainer.id}`}
                                withButton
                                imageURL={
                                   !trainer.properties ? '' :
                                       `${process.env.REACT_APP_HOST}/photos/${trainer.properties.photo}`
                               }
                                title={trainer.name}
                                onClick={() => showInfo(`/coaches/${trainer.id}`)}
                               />
                            ))
                    }
                </ArticlePage>
            )}
        </>
    );
};

export default Coaches;
