import React, {FC} from 'react';
import {Map} from '../styles/Map'
import {Breadcrumbs} from '../components';
import {useLocation} from 'react-router-dom';
import useDocumentTitle from '../utils/useDocumentTitle';


const Contacts: FC = () => {
    const location = useLocation();

    useDocumentTitle('Контакты | Искусство бокса', true);

    return (
        <>
            <Breadcrumbs
                location={location}
                title='Контакты'
            />
            <h1>Контакты</h1>
            <h2>Адрес офиса</h2>
            <p>Метро Дубровка. ул. 1 машиностроения, д. 10 (315 офис)</p>
            <h2>Email</h2>
            <p><a href='mailto:artboxing@bk.ru'>artboxing@bk.ru</a></p>
            <h2>Телефон</h2>
            <p><a href='tel:+79999797931'>+7 (999) 979-79-31</a></p>
            <h2>Схема проезда</h2>
            <Map>
                <iframe
                    allowFullScreen={true}
                    height='420'
                    src='https://yandex.ru/map-widget/v1/-/CCU4jTEZ3B'
                    style={{position: 'relative'}}
                    width='560'
                    title='map'
                >
                </iframe>
            </Map>
        </>
    );
};

export default Contacts;
