import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

type SendFeedbackType = {
    id: number,
    phone: string,
    name: string,
    token: string,
    subject: string
}

type SendFeedbackResultType = {
    result: boolean
    email_result: number
}

export const feedbackApi = createApi({
    reducerPath: 'feedbackApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_HOST + '/gym',
        mode: 'cors',
    }),
    endpoints: (build) => ({
        sendFeedback: build.mutation<SendFeedbackResultType, SendFeedbackType>({
            query: ({id, phone, name, token, subject}) => ({
                url: `/${id}/feedback`,
                method: 'POST',
                params: {
                    phone,
                    name,
                    captcha: token,
                    subject
                }
            }),
        }),
    })
});

export const {useSendFeedbackMutation} = feedbackApi
