import React from 'react'
import ContentLoader from 'react-content-loader'

export const TrainerCardLoader = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={160}
    viewBox='0 0 400 160'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
  >
    <rect x='11' y='139' rx='3' ry='3' width='210' height='6' />
    <rect x='5' y='-1' rx='0' ry='0' width='218' height='127' />
    <rect x='221' y='54' rx='0' ry='0' width='1' height='1' />
  </ContentLoader>
)
