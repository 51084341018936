import React, {FC, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../redux/store';
import { StyledPopup } from '../styles/StyledPopup';
import {setPopup} from '../redux/slices/uiSlice';

const Popup: FC = () => {
    const {popup} = useSelector((state: RootState) => state.ui)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (popup.isOpen) setTimeout(() => {
            dispatch(setPopup({}))
        }, 2000)
    }, [popup.isOpen, dispatch])

    return (
        <StyledPopup type={popup.type || 'info'} open={popup.isOpen || false}>
            {popup.text}
        </StyledPopup>
    );
};

export default Popup;
