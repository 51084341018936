import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    isAuth: false,
    token: ''
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        toggleAuth(state) {
            state.isAuth = !state.isAuth
        },
        setToken(state, action) {
            state.token = action.payload
        }
    },
})

export const {toggleAuth, setToken} = authSlice.actions

export default authSlice.reducer