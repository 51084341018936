import styled from 'styled-components';

const BurgerButton = styled.button<{open?: boolean}>`
    position: relative;
    width: 1.875rem;
    height: 1.875rem;
    z-index: 2;
    outline-offset: 0.3125rem;
    background-color: inherit;
    top: -25px;
    display: none;
    
    @media (max-width: 960px) {
        display: block;
    }
    
    @media (max-width: 640px) {
        top: -5px;
    }
    
    ${({open}) => open === true && `
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    
        span {
            top: 50%;
            display: block;
            
            &:nth-child(1) {
                -webkit-transform: translateY(-50%) rotate(45deg);
                transform: translateY(-50%) rotate(45deg);
            }
            &:nth-child(2) {
                display: none;                
            }
            &:nth-child(3) {
                -webkit-transform: translateY(-50%) rotate(-45deg);
                transform: translateY(-50%) rotate(-45deg);
            }
        }
    `}
`

const BurgerLine = styled.span<{
    top?: boolean
    bottom?: boolean
}>`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 0.125rem;
    background-color: #DF0F0F;
    -webkit-transition: top .2s, bottom .2s, -webkit-transform .2s;
    transition: top .2s, bottom .2s, transform .2s, -webkit-transform .2s;
    
    ${({top}) => top && `
        -webkit-transform: none;
        transform: none;
        top: 0;
    `}
    
    ${({bottom}) => bottom && `
        top: auto;
        -webkit-transform: none;
        transform: none;
        bottom: 0;
    `}
`

export {BurgerButton, BurgerLine}