import styled from 'styled-components';
import sheet from '../assets/main/sheet.jpg'

const About = {
    Wrap: styled.div`
      color: #fff;
      margin-bottom: 5rem;
    `,
    Row: styled.div`
      display: flex;

      @media (max-width: 960px) {
        flex-direction: column-reverse;
      }
    `,
    Project: styled.div`
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;

      padding-right: 0.625rem;
      padding-left: 0.625rem;
    `,
    Desc: styled.div`
      -webkit-animation: backInLeft 2s ease-out;
      animation: backInLeft 2s ease-out;

      @media (max-width: 960px) {
        text-align: center;
        margin-bottom: 1.25rem;
      }

      a.h1 {
        @media (max-width: 640px) {
          font-size: 2.2rem;
        }
      }

      a.h3 {
        @media (max-width: 640px) {
          font-size: 1.5rem;
        }
      }
    `,
    Animated: styled.div`
      align-items: center;
      align-self: center;
      text-transform: uppercase;
      word-spacing: 0.625rem;
      letter-spacing: .125rem;

      -webkit-animation: spin linear 30s infinite;
      animation: spin linear 30s infinite;
    `,
    Benefits: styled.div`
      padding-right: 0.625rem;
      padding-left: 0.625rem;
      flex: 2;

      @media (max-width: 960px) {
        margin-bottom: 1.25rem;
      }
    `,
    BenefitsWrap: styled.div`
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;

      img {
        position: absolute;
        top: 2.8125rem;
        z-index: 5;
        left: 0;

        -webkit-animation: slideInDown 1.5s ease-out;
        animation: slideInDown 1.5s ease-out;

        @media (max-width: 640px) {
          min-width: 0;
          width: 90%;
        }
      }

      div {
        @media (max-width: 960px) {
          padding-bottom: 3.125rem;
        }

        @media (max-width: 640px) {
          padding-top: 70vw;
        }
      }
    `,
    BenefitsList: styled.div`
      padding: 29.375rem 2.8125rem 8.4375rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      max-width: 31.25rem;
      background-repeat: no-repeat;
      background-size: cover;
      color: #000;
      -webkit-transition: opacity .2s;
      transition: opacity .2s;

      background-image: url(${sheet});

      -webkit-animation: slideInUp 1.5s ease-out;
      animation: slideInUp 1.5s ease-out;
    `,
    BenefitsTitle: styled.h2`
      @media (max-width: 640px) {
        font-size: 2.2rem;
      }
    `,
    BenefitsText: styled.h3`
      text-transform: uppercase;

      span {
        color: #be2e33;
      }
    `
}

export default About