import {FC} from 'react';
import {Breadcrumbs} from '../components';
import {useLocation} from 'react-router-dom';
import StyleProfile from '../styles/StyleProfile';
import ProfilePacks from '../components/Profile/ProfilePacks';
import ProfileRecords from '../components/Profile/ProfileRecords';
import ProfileInfo from '../components/Profile/ProfileInfo';

const Profile: FC = () => {
    const location = useLocation()

    return (
        <StyleProfile.Wrap>
            <Breadcrumbs
                location={location}
                title='Личный кабинет'
            />
            <h1>Личный кабинет</h1>
            <StyleProfile.Row>
                <StyleProfile.Lists>
                    <ProfilePacks/>
                    <ProfileInfo/>
                </StyleProfile.Lists>
                <ProfileRecords/>
            </StyleProfile.Row>
        </StyleProfile.Wrap>
    );
};

export default Profile;
