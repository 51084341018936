import React, {FC} from 'react';
import {ReactComponent as Vk} from '../assets/socials/vk.svg'
import {ReactComponent as Tw} from '../assets/socials/twitter.svg'
import {ReactComponent as Fb} from '../assets/socials/facebook.svg'
import {ReactComponent as Gl} from '../assets/socials/google-plus-g.svg'
import { SocialsHr, SocialsMain } from '../styles/Socials';

const Socials: FC = () => {
    return (
        <div>
            <SocialsHr/>
            <SocialsMain>
                <span>Поделиться в соц. сетях:</span>
                <a rel='nofollow' href='http://vkontakte.ru/share.php?url=http://artboxing.ru/contacts/index'
                   title='Вконтакте'>
                    <Vk/>
                </a>
                <a rel='nofollow'
                   href='http://twitter.com/intent/tweet?text=Контакты&amp;url=http://artboxing.ru/contacts/index'
                   title='twitter'>
                    <Tw/>
                </a>
                <a rel='nofollow' href='http://www.facebook.com/sharer.php?u=http://artboxing.ru/contacts/index'
                   title='facebook'>
                    <Fb/>
                </a>
                <a rel='nofollow' href='https://plus.google.com/share?url=http://artboxing.ru/contacts/index'
                   title='google plus'>
                    <Gl/>
                </a>
            </SocialsMain>
            <SocialsHr/>
        </div>
    );
};

export default Socials;
