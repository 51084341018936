import styled from 'styled-components';

const BreadcrumbsWrapper = styled.ul`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 0;
`

export default BreadcrumbsWrapper;
