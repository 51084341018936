import React, {FC} from 'react';
import StyleProfile, {RecordItem} from '../../styles/StyleProfile';
import {RecordType} from '../../types';
import {dateToPeopleView, removeSeconds} from '../../utils';

type RecordsProps = {
    title: string
    records: RecordType[]
}

const Records: FC<RecordsProps> = ({title, records}) => {
    const getStateByRecordInfo = (record: RecordType) => {
        let state: string
        let paidState: string

        if (record.charged || record.isPaid || record.isActivatePack || record.pack_id) {
            paidState = 'Оплачена'
        } else {
            paidState = 'Не оплачена'
        }

        if (record.in_past) {
            state = record.visited ? 'Посещена' : 'Не посещена'
        } else {
            state = 'Записан'
        }

        return `${state} (${record.deleted ? 'Отменена, ' : ''}${paidState})`
    }

    const generateRecordsHTML = (records: RecordType[]) => {
        return records.map((record, index) =>
            <StyleProfile.RecordItem key={index}>
                <RecordItem.DateTime>
                    <span>{dateToPeopleView(record.activitydate, 'long')}</span>
                    <span>{removeSeconds(record.starttime)} - {removeSeconds(record.endtime)}</span>
                </RecordItem.DateTime>
                <RecordItem.Activity>{record.activity_name}</RecordItem.Activity>
                <RecordItem.Coach>{record.coach}</RecordItem.Coach>
                <RecordItem.State>{getStateByRecordInfo(record)}</RecordItem.State>
            </StyleProfile.RecordItem>
        )
    }

    return (
        <StyleProfile.ActiveRecords>
            <h2>{title}</h2>
            {generateRecordsHTML(records)}
        </StyleProfile.ActiveRecords>
    );
};

export default Records;
