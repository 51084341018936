import React, {FC, useEffect, useRef, useState} from 'react';
import {clearError} from '../../utils';
import PhoneInput from 'react-phone-input-2';
import Button from '../../styles/components/Button';
import StyleProfile from '../../styles/StyleProfile';
import {UpdateType, useTokenInfoQuery, useUpdateMutation} from '../../services/AuthService';
import {isValidEmail, isValidPhone} from '../../utils/validation';
import {setPopup} from '../../redux/slices/uiSlice';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../../redux/store';

const ProfileInfo: FC = () => {
    const {token} = useSelector((state: RootState) => state.auth)
    const dispatch = useAppDispatch()

    const {data: info} = useTokenInfoQuery(token)
    const [update] = useUpdateMutation()

    const nameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (info !== undefined && nameRef.current && lastNameRef.current && emailRef.current) {
            nameRef.current.value = info.name
            lastNameRef.current.value = info.last_name
            emailRef.current.value = info.email
            setPhone(info.phone)
        }
    }, [info])
    const updateHandler = async () => {
        const name = nameRef.current?.value
        const lastName = lastNameRef.current?.value
        const email = emailRef.current?.value

        const params: UpdateType = {token}

        if (email && !isValidEmail(email)) {
            emailRef.current?.classList.add('error')
            setError('Email введен неверно.')
            return
        } else {
            params.email = email
        }

        if (!isValidPhone(phone)) {
            setError('Номер телефона введен неверно.')
            return
        } else {
            params.phone = phone
        }

        if (!name?.length) {
            nameRef.current?.classList.add('error')
            setError('Имя должно содержать хотя бы 1 символ.')
            return
        } else {
            params.name = name
        }

        params.last_name = lastName

        const res = await update(params).unwrap()
        if (res.result) {
            dispatch(setPopup({
                type: 'success',
                text: res.message || 'Сохранение данных произошло успешно'
            }))
        } else {
            dispatch(setPopup({
                type: 'error',
                text: res.message || 'Произошла ошибка при сохранении данных'
            }))
        }
    }

    return (
        <StyleProfile.Info>
            {error !== '' && <p style={{color: 'red'}}>{error ? error : 'Данные заполнены неверно.'}</p>}
            <>
                <label>Имя</label>
                <input
                    type='text'
                    onFocus={(e) => clearError(e, () => setError(''))}
                    ref={nameRef}
                />
            </>
            <>
                <label>Фамилия</label>
                <input
                    type='text'
                    onFocus={(e) => clearError(e, () => setError(''))}
                    ref={lastNameRef}
                />
            </>
            <>
                <label>Email</label>
                <input
                    type='text'
                    onFocus={(e) => clearError(e, () => setError(''))}
                    ref={emailRef}
                />
            </>
            <>
                <label>Телефон</label>
                <PhoneInput country={'ru'} value={phone} onChange={(pho) => setPhone(pho)}/>
            </>
            <Button colored='#DF0F0F' onClick={updateHandler}>Сохранить</Button>
        </StyleProfile.Info>
    );
};

export default ProfileInfo;
