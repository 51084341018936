import styled from 'styled-components';

export const StyledReviews = {
    Title: styled.h1`
      text-align: center;
    `,
    Content: styled.div`
      margin: 0;
      padding: 0;
    `,
    ContentTitle: styled.h5`
      line-height: 1.2;
      font-size: 1.3rem;
      margin-bottom: 0.625rem;
      font-weight: 600;
    `,
    Image: styled.img`
      float: right;
      margin-left: 1.25rem;
      max-width: 100%;
      height: auto;

      @media (max-width: 546px) {
        float: none;
        margin-left: 0;
        margin-bottom: 1.25rem;
      }
    `,
}
