import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { AllTrainersResponseType } from '../types';

export const trainersApi = createApi({
    reducerPath: 'trainersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_HOST + '/gym',
        mode: 'cors',
    }),
    endpoints: (build) => ({
        getAllTrainers: build.query<Array<AllTrainersResponseType>, number>({
            query: (id) =>  `/${id}/trainers/all`,
        }),
    })
});

export const {useGetAllTrainersQuery} = trainersApi
