import React, {FC} from 'react';
import {Breadcrumbs} from '../components';
import {useLocation} from 'react-router-dom';

const NotFound: FC = () => {
    const location = useLocation();

    return (
        <>
            <Breadcrumbs
                location={location}
                title='Страница не найдена'
            />
            <h1>NotFound</h1>
        </>
    );
};

export default NotFound;
