import React, {FC} from 'react';
import {Breadcrumbs} from '../components';
import {Link, useLocation, useNavigate} from 'react-router-dom';

type PayProps = {
    success?: boolean
}

const Pay: FC<PayProps> = ({success}) => {
    const location = useLocation()
    const navigate = useNavigate()

    setTimeout(() => {
        navigate('/profile')
    }, 10000)

    return (
        <>
            <Breadcrumbs
                location={location}
                title='Оплата'
            />
            {success
                ? <h1>Оплата прошла успешно</h1>
                : <h1>Произошла ошибка при оплате</h1>
            }
            <h2>• <Link to={'/'}>На главную</Link></h2>
            <h2>• <Link to={'/schedule'}>В расписание</Link></h2>
            <h2>• <Link to={'/products'}>Продукты</Link></h2>
            <h2>• <Link to={'/profile'}>Личный кабинет</Link></h2>
        </>
    );
};

export default Pay;
