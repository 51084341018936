import styled from 'styled-components';
import {Modal} from './index';

const StyledModalDialog = {
    ...Modal,
    Title: styled.h2`
      text-align: center;
    `,
    ButtonWrapper: styled.div`
      display: flex;
      flex-direction: column;
      padding: 0 100px;
    `,
    Text: styled.p`
      text-align: center;
    `
}

export default StyledModalDialog
