import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

type CreateCartType = {
    id: number
    token: string
    products: number
    productCost: string
}

type CreateCartResultType ={
    result: number
    id?: number
}

export const cartApi = createApi({
    reducerPath: 'cartApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_HOST,
        mode: 'cors',
    }),
    endpoints: (build) => ({
        createCart: build.mutation<CreateCartResultType, CreateCartType>({
            query: ({id, token, products, productCost}) => ({
                url: `gym/${id}/cart`,
                method: 'POST',
                params: {
                    token,
                    products,
                    'product-cost': productCost
                }
            })
        })
    })
});

export const {useCreateCartMutation} = cartApi
