import styled from 'styled-components';


const StyledArticleCard = {
    Title: styled.h5`
      line-height: 1.2;
      font-size: 1.3rem;
      margin-bottom: 1.5rem;
      font-weight: 600;
      height: 50px;
    
      @media (max-width: 546px) {
       text-align: center;
       height: auto; 
      }
    `,
    ImageContainer: styled.div`
      width: 90%;
      height: 240px;
      display: inline-block;
      margin-bottom: 1rem;
      overflow: hidden;
      -webkit-transition: -webkit-transform .3s, -webkit-box-shadow .3s;
      transition: transform .3s, box-shadow .3s, -webkit-transform .3s, -webkit-box-shadow .3s;
      
      @media (max-width: 546px) {
        width: 100%;
        height: 320px;
        transition: none;
      }
    `,
    Image: styled.img`
      height: 100%;
      width: 100%;
    `,
    Wrapper: styled.div`
      width: 31%;
      margin-right: 0.3rem;
      margin-bottom: 1rem;
      display: flex;
      flex-flow: row wrap;
      flex-direction: column;
      button {
        padding: 0.75rem 1.5rem;
      }
      
      @media (min-width: 547px) and (max-width: 1024px) {
        width: 46%;
      }
    
      @media (max-width: 546px) {
        width: 100%;
        height: 50%;
        button {
          width: 100%;
        }
      }

      & div:hover {
        transform: scale(1.05);
        -webkit-box-shadow: 6px 9px 15px -7px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 6px 9px 15px -7px rgba(34, 60, 80, 0.2);
        box-shadow: 6px 9px 15px -7px rgba(34, 60, 80, 0.2);
        @media (max-width: 546px) {
          transform: none;
        }
      }
    `,
}

export default StyledArticleCard;
