import {FC} from 'react';
import {StyledContentLoader} from '../../styles/StyledContentLoader';

const RecordsLoader: FC<{ marginTop?: number }> = (props) => {

    const size = window.innerWidth > 840 ? 650 : 363

    return <StyledContentLoader.Wrap
        speed={2}
        width={size}
        height={490}
        viewBox={`0 0 ${size} 490`}
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        margintop={props.marginTop}
    >
        <rect x='166' y='446' rx='0' ry='0' width='1' height='0'/>
        <rect x='185' y='451' rx='0' ry='0' width='0' height='1'/>
        <rect x='0' y='0' rx='3' ry='3' width='180' height='30'/>
        <rect x='0' y='65' rx='0' ry='0' width={size} height='135'/>
        <rect x='0' y='225' rx='0' ry='0' width={size} height='135'/>
        <rect x='0' y='395' rx='0' ry='0' width={size} height='135'/>
    </StyledContentLoader.Wrap>
}

export default RecordsLoader