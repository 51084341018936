import React, {FC} from 'react';
import {Breadcrumbs} from '../components';
import {useLocation} from 'react-router-dom';
import {useGetCategoriesQuery, useGetProductsQuery} from '../services/Products';
import StyledProducts from '../styles/StyledProducts';
import Button from '../styles/components/Button';
import {useCreateCartMutation} from '../services/CartService';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from '../redux/store';
import {setPopup, toggleModalLogin} from '../redux/slices/uiSlice';
import {CategoriesType, ProductType} from '../types';
import useDocumentTitle from '../utils/useDocumentTitle';

const Products: FC = () => {
    const location = useLocation();
    const dispatch = useAppDispatch()
    const {data: products} = useGetProductsQuery({id: 138})
    const {data: categories} = useGetCategoriesQuery({id: 138})
    const {isAuth, token} = useSelector((state: RootState) => state.auth)
    const [createCart] = useCreateCartMutation()

    useDocumentTitle('Продукты | Искусство бокса', true);

    const buyHandler = (productId: number, productCost: string) => {
        if (!isAuth) {
            dispatch(toggleModalLogin())
            dispatch(setPopup({
                type: 'error',
                text: 'Требуется авторизация'
            }))
            return
        }
        (async () => {
            const res = await createCart({id: 138, token, products: productId, productCost}).unwrap()
            if (res.result) {
                document.location = process.env.REACT_APP_HOST + `/cart/138/${res.id}/pay?token=${token}`
            } else {
                dispatch(setPopup({
                    type: 'error',
                    text: 'Упс, что-то пошло не так...'
                }))
            }
        })()
    }

    const generateProductsHTML = (category: CategoriesType, products: ProductType[]) => {
        products = products.filter((product: ProductType) => product.categories.includes(category.id))
        if (!products.length) return
        return <div key={category.id}>
            <h2>{category.name}</h2>
            {
                products
                .map((product: ProductType) =>
                    <StyledProducts.Product key={product.id}>
                        <div>
                            <StyledProducts.ProductName>{product.name}</StyledProducts.ProductName>
                            <StyledProducts.ProductDescription
                                dangerouslySetInnerHTML={{__html: product.description}}/>
                        </div>
                        <div>
                            <StyledProducts.ProductCost>{product.cost} ₽</StyledProducts.ProductCost>
                            <Button colored={'#DF0F0F'}
                                    onClick={() => buyHandler(product.id, product.cost)}>Купить</Button>
                        </div>
                    </StyledProducts.Product>
                )
            }
        </div>
    }

    return (
        <StyledProducts.Wrap>
            <Breadcrumbs
                location={location}
                title='Продукты'
            />
            <h1>Продукты</h1>
            <>
                {categories && products &&
                    <div>
                        {categories.map((category) => generateProductsHTML(category, products))}
                    </div>
                }
            </>
        </StyledProducts.Wrap>
    );
};

export default Products;
