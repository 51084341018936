import { configureStore } from '@reduxjs/toolkit'
import {useDispatch, useSelector} from 'react-redux';
import uiSlice from './slices/uiSlice';
import authSlice from './slices/authSlice';
import {authApi} from '../services/AuthService';
import { trainersApi } from '../services/TrainersServices';
import { TypedUseSelectorHook } from 'react-redux';
import {packsApi} from '../services/PacksService';
import {recordsApi} from '../services/RecordsService';
import {scheduleApi} from '../services/ScheduleServices';
import {productsApi} from '../services/Products';
import {cartApi} from '../services/CartService';
import {feedbackApi} from '../services/FeedbackService';

export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [trainersApi.reducerPath]: trainersApi.reducer,
        [packsApi.reducerPath]: packsApi.reducer,
        [recordsApi.reducerPath]: recordsApi.reducer,
        [scheduleApi.reducerPath]: scheduleApi.reducer,
        [productsApi.reducerPath]: productsApi.reducer,
        [cartApi.reducerPath]: cartApi.reducer,
        [feedbackApi.reducerPath]: feedbackApi.reducer,
        ui: uiSlice,
        auth: authSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        authApi.middleware,
        trainersApi.middleware,
        packsApi.middleware,
        recordsApi.middleware,
        scheduleApi.middleware,
        recordsApi.middleware,
        productsApi.middleware,
        cartApi.middleware,
        feedbackApi.middleware,
    )
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
