import React, {FC} from 'react';
import SwiperComponent from '../components/SwiperComponent';
import {Breadcrumbs} from '../components';
import {useLocation} from 'react-router-dom';
import useDocumentTitle from '../utils/useDocumentTitle';
import slider1 from '../assets/about/slider1.jpg';
import slider2 from '../assets/about/slider2.jpg';
import slider3 from '../assets/about/slider3.jpg';
import {Autoplay} from 'swiper';

const slides = [
    <img src={slider1} alt='slider1'/>,
    <img src={slider2} alt='slider2'/>,
    <img src={slider3} alt='slider3'/>
]

const About: FC = () => {
    const location = useLocation();

    useDocumentTitle('О нас | Искусство бокса', true);


    return (
        <>
            <>
                <Breadcrumbs
                    location={location}
                    title='О нас'
                />
                <h1>О нас</h1>
                <h2>Наш проект и глобальная цель</h2>
                <p>Бокс нас вдохновляет и зажигает, мы хотим поделиться этим с другими, потому создали эту студию.
                    Приходите, и вы тоже больше не сможете жить без этого спорта.</p>
                <p>Все еще думаете, что бокс — это просто драка, грубое махание кулаками? В нашей студии мы покажем,
                    всю
                    красоту этого спорта. Искусство верных движений, захватывающая стратегия ведения боя и защиты,
                    предугадывание действий соперника. Разве это можно назвать дракой? Мы хотим познакомить всех с
                    удивительным миром бокса.</p>
                <p>Если вы еще не определились, каким спортом заниматься, то наша студия поможет сделать осознанный
                    выбор.</p>
                <p>Кроме отработок движений и тренировок ведения боя мы сможем помочь развить реакцию и скорость
                    мышления, вы научитесь наперёд предугадывать действия,
                    как в ринге, так и в жизни. Также обучим координации движений, Ваше тело будет находиться в согласии
                    с мозгом, что упрощает жизнедеятельность. И самое главное, вы сможете разгрузиться эмоционально,
                    многие психологи считают бокс, одним из лучших методов эмоциональной перезагрузки.
                </p>
            </>
            <SwiperComponent
                slides={slides}
                modules={[Autoplay]}
                arrowWidth={63}
            />
        </>
    );
};

export default About;
