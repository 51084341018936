import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './sass/index.scss'
import {BrowserRouter} from 'react-router-dom';
import {store} from './redux/store';
import {Provider} from 'react-redux';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    // <React.StrictMode>
    // TODO: RECAPTCHA_SITE_KEY of seva.kamenovsky63@gmail.com, need replace to erpico corp acc
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}>
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
    </GoogleReCaptchaProvider>
    // </React.StrictMode>
);
