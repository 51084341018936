import React, {FC, useState} from 'react';
import {Swiper as SwiperClass, SwiperModule} from 'swiper/types';
import 'swiper/css';
import 'swiper/css/navigation';
import StyledSwiper from '../styles/StyledSwiper';

type SwiperComponentType = {
    slides: JSX.Element[],
    modules?: SwiperModule[]
    arrowWidth: number
}

const SwiperComponent: FC<SwiperComponentType> = ({slides, modules, arrowWidth}) => {
    const [swiperRef, setSwiperRef] = useState<SwiperClass>();

    const prevPage = () => {
        if (swiperRef) swiperRef.slidePrev()
    }

    const nextPage = () => {
        if (swiperRef) swiperRef.slideNext()
    }

    return (
        <StyledSwiper.Wrapper>
            <StyledSwiper.Button arrowwidth={arrowWidth} rotate={180} onClick={prevPage}/>

            <StyledSwiper.Swiper
                modules={modules}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false
                }}
                loop={true}
                onSwiper={setSwiperRef}
            >
                {slides.map((slide, index) =>
                    <StyledSwiper.SwiperSlide key={index}>{slide}</StyledSwiper.SwiperSlide>
                )}
            </StyledSwiper.Swiper>

            <StyledSwiper.Button arrowwidth={arrowWidth} onClick={nextPage}/>
        </StyledSwiper.Wrapper>
    );
};

export default SwiperComponent;
