import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import Main from './pages/Main';
import NotFound from './pages/NotFound';
import Destinations from './pages/Destinations';
import Auction from './pages/Auction';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Coaches from './pages/Coaches';
import MainLayout from './layouts/MainLayout';
import MobileMenu from './components/MobileMenu';
import SiteMap from './pages/SiteMap';
import Description from './pages/Description';
import PrivacyStatement from './pages/PrivacyStatement';
import Reviews from './pages/Reviews';
import Prices from './pages/Prices';
import SocialsAndCallback from './layouts/SocailsAndCallback';
import Modals from './components/Modals';
import Profile from './pages/Profile';
import CoachDescription from './pages/CoachDescription';
import {useCookies} from 'react-cookie';
import {useTokenInfoQuery} from './services/AuthService';
import {useSelector} from 'react-redux';
import {RootState, useAppDispatch} from './redux/store';
import {setToken, toggleAuth} from './redux/slices/authSlice';
import Products from './pages/Products';
import Schedule from './pages/Schedule';
import Pay from './pages/Pay';

function App() {
    const {isAuth} = useSelector((state: RootState) => state.auth)
    const dispatch = useAppDispatch()
    const [{token}] = useCookies(['token'])
    const {data} = useTokenInfoQuery(token)

    useEffect(() => {
        if (data?.result) {
            dispatch(toggleAuth())
            dispatch(setToken(token))
        }
    }, [data, dispatch, token])

    return (
        <>
            <Routes>
                {/*Todo: Нужно будет сделать массив с приватными
                и публичными роутами, вроде видел где то такой функционал*/}
                <Route path={'/'} element={<Main/>}/>
                <Route path={'/'} element={<MainLayout/>}>
                    <Route path={'/'} element={<SocialsAndCallback/>}>
                        <Route path={'/destinations'} element={<Destinations/>}/>
                        <Route path={'/destinations/:trainings'} element={<Description/>}/>
                        <Route path={'/auction'} element={<Auction/>}/>
                        <Route path={'/auction/:auction'} element={<Description/>}/>
                        <Route path={'/about'} element={<About/>}/>
                        <Route path={'/contacts'} element={<Contacts/>}/>
                        <Route path={'/coaches'} element={<Coaches/>}/>
                        <Route path={'/coaches/:id'} element={<CoachDescription/>}/>
                        <Route path={'/sitemap'} element={<SiteMap/>}/>
                        <Route path={'/privacy_statement'} element={<PrivacyStatement/>}/>
                        <Route path={'/reviews'} element={<Reviews/>}/>
                        <Route path={'/prices'} element={<Prices/>}/>
                        <Route path={'/products'} element={<Products/>}/>
                        <Route path={'/schedule'} element={<Schedule/>}/>
                        <Route path={'/pay/ok'} element={<Pay success={true}/>}/>
                        <Route path={'/pay/error'} element={<Pay success={false}/>}/>
                        {isAuth && <Route path={'/profile'} element={<Profile/>}/>}
                        <Route path={'*'} element={<NotFound/>}/>
                    </Route>
                </Route>
            </Routes>
            <MobileMenu/>
            <Modals/>
        </>
    );
}

export default App;
