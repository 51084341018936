import styled from 'styled-components';


const StyledTrainerCard = {
    Title: styled.h5`
      line-height: 1.2;
      font-size: 1.3rem;
      margin-bottom: 1.5rem;
      font-weight: 600;
      height: 50px;
    
      @media (max-width: 546px) {
       text-align: center;
       height: auto; 
      }
    `,
    Image: styled.img`
      object-fit: cover;
      height: 30vh;
      margin-bottom: 1rem;
      -webkit-transition: -webkit-transform .3s, -webkit-box-shadow .3s;
      transition: transform .3s, box-shadow .3s, -webkit-transform .3s, -webkit-box-shadow .3s;

      @media (max-width: 546px) {
        display: block;
        margin: 0 auto 1rem auto;
        transition: none;
      }
    `,
    Wrapper: styled.div`
      width: 31%;
      margin-right: 0.3rem;
      margin-bottom: 1rem;
      display: flex;
      flex-flow: row wrap;
      flex-direction: column;

      button {
        padding: 0.75rem 1.5rem;
      }
      
      @media (min-width: 547px) and (max-width: 1024px) {
        width: 46%;
      }
    
      @media (max-width: 546px) {
        width: 100%;
        align-items: center;
        a {
          width: 70%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0 1rem;
        }
        button {
          width: 100%;
        }
      }

      & img:hover {
        transform: scale(1.05);
        -webkit-box-shadow: 6px 9px 15px -7px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 6px 9px 15px -7px rgba(34, 60, 80, 0.2);
        box-shadow: 6px 9px 15px -7px rgba(34, 60, 80, 0.2);
        @media (max-width: 546px) {
          transform: none;
        }
      }
    `,
}

export default StyledTrainerCard;
