import React, {FC, MouseEvent, useRef, useState} from 'react';
import Form from '../styles/components/Form';
import Button from '../styles/components/Button';
import {useClientCheckMutation, useGetCodeMutation, useLoginMutation} from '../services/AuthService';
import {useCookies} from 'react-cookie';
import {isValidCode, isValidEmail, isValidPhone} from '../utils/validation';
import {clearError} from '../utils';
import StyledLoginForm from '../styles/StyledLoginForm';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

type AuthParamsType = {
    email: string
    phone?: string
    name?: string
}

const LoginForm: FC = () => {
    const [, setCookie] = useCookies(['token']);
    const [isCodeSend, setIsCodeSend] = useState(false);

    const emailInputRef = useRef<HTMLInputElement>(null);
    const codeInputRef = useRef<HTMLInputElement>(null);
    const [phone, setPhone] = useState('');
    const nameInputRef = useRef<HTMLInputElement>(null);
    const privacyInputRef = useRef<HTMLInputElement>(null);

    const [getCode, {isLoading: isLoadingCode}] = useGetCodeMutation()
    const [login, {isLoading: isLoadingLogin}] = useLoginMutation()
    const [clientCheck, {isLoading: isLoadingClientCheck}] = useClientCheckMutation()
    const [error, setError] = useState('');

    const [activeTab, setActiveTab] = useState<'login' | 'registration'>('login');

    const getCodeHandler = async (e: MouseEvent) => {
        e.preventDefault()

        const email = emailInputRef.current?.value
        const authParams: AuthParamsType = {
            email: email || ''
        }

        if (email && !isValidEmail(email)) {
            setError('Email введен неверно')
            emailInputRef.current?.classList.add('error')
            return
        }
        if (activeTab === 'registration') {
            authParams.name = nameInputRef.current?.value
            authParams.phone = phone

            if (!isValidPhone(phone)) {
                setError('Номер телефона введен неверно')
                return
            }

            if (authParams.name?.length === 0) {
                setError('Введите имя')
                nameInputRef.current?.classList.add('error')
                return
            }

            if (!privacyInputRef.current?.checked) {
                setError('Дайте согласия на обработку персональных данных')
                privacyInputRef.current?.classList.add('error')
                return
            }
        }

        if (activeTab === 'login' && email) {
            try {
                await clientCheck(email).unwrap()
            } catch (e) {
                setError('Пользователь не найден. Зарегистрируйтесь.')
                setActiveTab('registration')
                console.log(e)
                return
            }
        }
        if (!emailInputRef.current) return
        try {
            if (activeTab === 'login') delete authParams.phone
            const res = await getCode(authParams).unwrap()
            if (res.result) {
                setIsCodeSend(true)
                emailInputRef.current.disabled = true
                emailInputRef.current.classList.add('disabled')
            }
        } catch (e) {
            console.log(e)
        }
    }

    const loginHandler = async (e: MouseEvent) => {
        e.preventDefault()

        const email = emailInputRef.current?.value
        const code = codeInputRef.current?.value

        if (code && !isValidCode(code)) {
            setError('Код подтверждения введен неверно')
            codeInputRef.current.classList.add('error')
            return
        }

        if (!email || !code) return

        try {
            const res = await login({email, code}).unwrap()
            const expires = new Date()
            expires.setMonth(expires.getMonth() + 1)

            if (res.result) {
                setCookie('token', res.token, {
                    path: '/',
                    expires
                })
                window.location.reload()
            } else {
                setError(res.message || 'Произошла ошибка при входе')
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Form.Wrap>
            <StyledLoginForm.TabContainer style={{display: 'flex', justifyContent: 'space-around'}}>
                <StyledLoginForm.Tab active={activeTab === 'login'}
                                     onClick={() => setActiveTab('login')}>Авторизация</StyledLoginForm.Tab>
                <StyledLoginForm.Tab active={activeTab === 'registration'}
                                     onClick={() => setActiveTab('registration')}>Регистрация</StyledLoginForm.Tab>
            </StyledLoginForm.TabContainer>
            <Form.Text>
                {activeTab === 'login'
                    ? 'Введите email, вам придет код для авторизации'
                    : 'Введите свои данные, после - отправьте код подтверждения, он придет в смс'
                }
            </Form.Text>
            <Form.Row>
                {(error !== '') && <p style={{color: 'red'}}>{error ? error : 'Произошла ошибка при входе.'}</p>}
                <label>
                    Email
                    <input type='text' onFocus={(e) => clearError(e, () => setError(''))} ref={emailInputRef}/>
                </label>
                {
                    activeTab === 'registration' &&
                    <>
                        <label>
                            Номер телефона
                            <PhoneInput country={'ru'} value={phone} onChange={(pho) => setPhone(pho)}/>
                        </label>
                        <label>
                            Имя
                            <input type='text' onFocus={(e) => clearError(e, () => setError(''))} ref={nameInputRef}/>
                        </label>
                        <div>
                            <input type='checkbox' style={{margin: '0 10px 1rem 0'}} ref={privacyInputRef}/>
                            Согласен с обработкой
                            <a
                                href={'/privacy_statement'}
                                target={'_blank'}
                                rel='noopener noreferrer' style={{color: 'red'}}
                            >
                                персональных данных
                            </a>
                        </div>
                    </>
                }
                {
                    isCodeSend &&
                    <label>
                        Код подтверждения
                        <input type='text' onFocus={(e) => clearError(e, () => setError(''))} ref={codeInputRef}/>
                    </label>
                }
                {!isCodeSend
                    ? <Button colored={'#DF0F0F'} disabled={isLoadingClientCheck || isLoadingCode}
                              onClick={getCodeHandler}>Отправить код</Button>
                    : <Button colored={'#DF0F0F'} disabled={isLoadingLogin} onClick={loginHandler}>Войти</Button>}
            </Form.Row>
        </Form.Wrap>
    );
};

export default LoginForm;

