import React, {FC} from 'react';
import {Button} from '../../styles/components';
import {StyledArticleCard} from '../../styles/components';
import { Link } from 'react-router-dom';

type ArticleCardPropsType = {
    imageURL: string;
    title: string;
    onClick: () => void;
    href: string;
    withButton: boolean;
}

export const ArticleCard: FC<ArticleCardPropsType> = ({imageURL, title, onClick, href, withButton}) => {
    return (
        <StyledArticleCard.Wrapper onClick={onClick}>
            <Link to={href}>
                <StyledArticleCard.ImageContainer>
                    <StyledArticleCard.Image
                        src={imageURL.length ? imageURL : require('../../assets/image-preview.png')}
                        alt={title}
                    />
                </StyledArticleCard.ImageContainer>
                <StyledArticleCard.Title>{title}</StyledArticleCard.Title>
                {withButton ?
                    (
                        <Button
                        transparent={false}
                        colored='#DF0F0F'
                        >Узнать больше
                        </Button>
                    ) : null}
            </Link>
        </StyledArticleCard.Wrapper>
    )
}
