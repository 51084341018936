import React, {FC} from 'react';
import {ReactComponent as Logo} from '../assets/main/logo.svg'
import Container from '../styles/components/Container';
import StyledHeader from '../styles/Header';
import Burger from './Burger';
import {Link, useNavigate} from 'react-router-dom';
import {RootState, useAppDispatch} from '../redux/store';
import {toggleModalLogin, toggleModalQuestions} from '../redux/slices/uiSlice';
import {useCookies} from 'react-cookie';
import {useSelector} from 'react-redux';

const Header: FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [, , removeCookie] = useCookies(['token'])
    const {isAuth} = useSelector((state: RootState) => state.auth)

    const iconClickHandler = () => {
        if (isAuth){
            navigate('/profile')
        } else {
            dispatch(toggleModalLogin())
        }
    }

    const exitClickHandler = () => {
        removeCookie('token')
        navigate('/')
        window.location.reload()
    }


    return (
        <header style={{paddingTop: '1.5625rem'}}>
            <Container>
                <StyledHeader.Row>
                    <StyledHeader.Logo to={'/'}>
                        <Logo/>
                    </StyledHeader.Logo>
                    <StyledHeader.Nav>
                        <StyledHeader.List>
                            <StyledHeader.ListElement>
                                <Link to='/destinations'>Направления</Link>
                            </StyledHeader.ListElement>
                            <StyledHeader.ListElement>
                                <Link to='/schedule'>Расписание</Link>
                            </StyledHeader.ListElement>
                            <StyledHeader.ListElement>
                                <Link to='/products'>Продукты</Link>
                            </StyledHeader.ListElement>

                            {/*<StyledHeader.ListElement>*/}
                            {/*    <Link to='/auction'>Промо-акции</Link>*/}
                            {/*</StyledHeader.ListElement>*/}
                            <StyledHeader.ListElement>
                                <Link to='/about'>О нас</Link>
                            </StyledHeader.ListElement>
                            {/*<StyledHeader.ListElement>*/}
                            {/*    <Link to='/contacts'>Контакты</Link>*/}
                            {/*</StyledHeader.ListElement>*/}
                            <StyledHeader.ListElement>
                                <Link to='/coaches'>Тренеры</Link>
                            </StyledHeader.ListElement>
                        </StyledHeader.List>
                        <StyledHeader.Extra>
                            <i className='fa-regular fa-user' onClick={iconClickHandler}/>
                            {
                                isAuth &&
                                <i className='fa-regular fa-arrow-right-from-bracket' onClick={exitClickHandler}/>
                            }
                            <StyledHeader.Call>
                                <StyledHeader.Num href='tel:+79999797931'>+7 (999) 979-79-31</StyledHeader.Num>
                                <StyledHeader.Callback onClick={() => dispatch(toggleModalQuestions())}>
                                    Заказать звонок
                                </StyledHeader.Callback>
                            </StyledHeader.Call>
                        </StyledHeader.Extra>
                        <Burger/>
                    </StyledHeader.Nav>
                </StyledHeader.Row>
            </Container>
        </header>
    );
};

export default Header;
