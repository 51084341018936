import styled from 'styled-components';
import Filling from '../assets/main/filling.jpg';

const Studio = {
    Wrap: styled.div`
      padding-top: 6.25rem;
      color: white;

      @media (max-width: 960px) {
        margin-bottom: 3.125rem;
      }

      @media (max-width: 640px) {
        font-size: .75rem;
        margin: 0 0 0.9375rem 0;
      }
    `,
    WordWrap: styled.div`
      @media (max-width: 1200px) {
        display: flex;
        justify-content: center;
      }
    `
    ,
    Word: styled.div`
      font-size: 12.5rem;
      display: inline-block;
      color: transparent;
      background: url(${Filling});
      background-size: cover;
      font-weight: 800;
      text-transform: uppercase;
      -webkit-background-clip: text;
      line-height: 1;
      letter-spacing: .3125rem;

      -webkit-animation: flipInX 2s ease-out;
      animation: flipInX 2s ease-out;

      @media (max-width: 1200px) {
        font-size: 7.5rem;
      }

      @media (max-width: 640px) {
        font-size: 3.125rem;
        margin-bottom: 1.25rem;
      }
    `,
    Row: styled.div`
      display: flex;

      @media (max-width: 640px) {
        flex-direction: column-reverse;
      }
    `,
    Text: styled.div`
      width: 41.6%;

      -webkit-animation: backInLeft ease-out 2s;
      animation: backInLeft ease-out 2s;

      @media (max-width: 960px) {
        width: 58.3%;
      }

      @media (max-width: 640px) {
        text-align: center;
        width: unset;
      }
    `,
    Title: styled.div`
      h1 {
        font-size: 5.625rem;
        line-height: 1;
        margin-bottom: 3.125rem;
        font-family: 'Iskra', sans-serif;

        @media (max-width: 1200px) {
          font-size: 4.375rem;
        }

        @media (max-width: 960px) {
          font-size: 3.125rem;
        }

        @media (max-width: 640px) {
          font-size: 2.5rem;
        }
      }

      button {
        width: 100%;

        @media (max-width: 640px) {
          font-size: .75rem;
          margin: 0 0 0.9375rem 0;
        }
      }
    `,
    Gloves: styled.div`
      position: relative;
      top: -3rem;
      -webkit-filter: grayscale(0.95);
      filter: grayscale(0.95);
      width: 58.3%;

      -webkit-transition: opacity .2s, -webkit-filter .2s;
      transition: filter .2s, opacity .2s, -webkit-filter .2s;

      -webkit-animation: backInRight ease-out 2s;
      animation: backInRight ease-out 2s;

      &:hover {
        -webkit-filter: none;
        filter: none;
      }

      img {
        width: 100%;
        @media (max-width: 640px) {
          width: 46%;
        }
      }

      @media (max-width: 960px) {
        width: 41.6%;
      }

      @media (max-width: 640px) {
        text-align: center;
        top: 0;
        width: unset;
        margin-bottom: 1.25rem;
      }
    `
}

export default Studio