import styled from 'styled-components';

const SocialsHr = styled.hr`
    margin: 2.625rem auto 2.625rem;
    border-bottom: 0.0625rem solid #e6e6e6;
`

const SocialsMain = styled.div`
    display: flex;
    align-items: center;
    column-gap: 7px;

    a {
        svg {   
            width: 2.3em;
            height: 2.3em;
            transform-origin: 0.5625em 0.5em;
            color: white;
            background-color: black;
            border-radius: 50%;
            -webkit-transition: color 200ms ease-in-out;
            transition: background 200ms ease-in-out;
            
            &:hover {
                background-color: #DF0F0F;
            }
        }
    }
    
    span {
        @media (max-width: 640px) {
            display: none;
        }
    }
`

export {SocialsMain, SocialsHr}