import React, {FC, useEffect, useState} from 'react';
import {ArticleCard, Breadcrumbs, List} from '../components';
import {ArticleType} from '../types';
import {ArticlePage, Paginate} from '../styles/components';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import articlesJSON from '../assets/articles.json';
import useDocumentTitle from '../utils/useDocumentTitle';

const Destinations: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [articles, setArticles] = useState<Array<ArticleType>>([]);
    const [trainings, setTrainings] = useState<Array<string>>([]);
    const [currentItems, setCurrentItems] = useState<Array<ArticleType>>([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 6;
    const location = useLocation();
    const navigate = useNavigate();

    useDocumentTitle('Направления | Искусство бокса', true);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setTrainings([
                'Персональная тренировка',
                'Сплит-тренировка (в паре)',
                'Мини-Группа (до 4-х человек)'
            ])

        }
        return () => {
            mounted = false;
        };
    }, []);


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const data = articlesJSON as Array<ArticleType>;
            setArticles(data)
        }
        return () => {
            mounted = false;
        };
    }, []);


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const page = searchParams.get('page');
            if (page !== null) {
                setCurrentPage((+page) - 1);
                const newOffset = (currentPage * itemsPerPage) % articles.length;
                setItemOffset(newOffset);
            }
        }
        return () => {
            mounted = false;
        };
    }, [searchParams, articles.length, currentPage]);


    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const endOffset = itemOffset + itemsPerPage;
            setCurrentItems(articles.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(articles.length / itemsPerPage));
        }
        return () => {
            mounted = false;
        };
    }, [itemOffset, articles, itemsPerPage]);

    const handlePageClick = ({selected}: { selected: number }) => {
        setSearchParams(`page=${selected + 1}`);
    }

    const handleClick = (href: string) => {
        navigate(href);
    }


    return (
        <>
            <Breadcrumbs
                location={location}
                title='Направления'
            />
            <h1>Направления</h1>
            <div>
                <h3>{trainings.length} формата тренировок</h3>
                <List items={trainings}/>
                <ArticlePage>
                    {currentItems.map((item: ArticleType) => (
                        <ArticleCard
                            key={item.id}
                            imageURL={require(`../assets/destinations${item.imageUrl}`)}
                            title={item.title}
                            onClick={() => handleClick(item.href)}
                            href={item.href}
                            withButton={false}
                        />
                    ))}
                </ArticlePage>
                <Paginate
                    nextLabel='Следующая »'
                    previousLabel='« Предыдущая '
                    onPageChange={handlePageClick}
                    pageLinkClassName='page-num'
                    previousLinkClassName='page-num'
                    nextLinkClassName='page-num'
                    containerClassName='pagination-articles'
                    pageCount={pageCount}
                    forcePage={currentPage}
                    hrefBuilder={(pageIndex) => '/destinations/?page=' + pageIndex}
                />
            </div>
        </>
    );
};

export default Destinations;
