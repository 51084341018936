import styled from 'styled-components';

const Map = styled.div`
    position: relative;
    overflow: hidden;
    
    iframe {
        max-width: 100%;
    }
`

export {Map}