import {FC, useCallback, useEffect, useState} from 'react';
import {Breadcrumbs} from '../components'
import { AllTrainersResponseType } from '../types';
import useDocumentTitle from '../utils/useDocumentTitle';
import {StyledCoachDescription} from '../styles/StyledCoachDescription';
import {ReactComponent as Instagram} from '../assets/socials/instagram.svg';
import {useLocation, Location, useParams} from 'react-router-dom';
import { SocialsMain } from '../styles/Socials';
import {useGetAllTrainersQuery} from '../services/TrainersServices';

const CoachDescription: FC = () => {
    const location: Location = useLocation();
    const [descriptions, setDescriptions] = useState<Array<string>>([]);
    const params = useParams();
    const [trainer, setTrainer] = useState<AllTrainersResponseType>({} as AllTrainersResponseType);
    const {data, error} = useGetAllTrainersQuery(138);

    useDocumentTitle('Тренеры | Искусство бокса', true);


    const fetchData = useCallback(
        () => {
            let _trainer: AllTrainersResponseType | undefined = {} as AllTrainersResponseType

            if (data && params.id) {
                const queryId = params.id;
                _trainer = data.find((t: AllTrainersResponseType) => {
                    return t.id === queryId
                });
            }

            if (typeof  _trainer !== 'undefined') {
                setTrainer(_trainer);
            }
        },
        [params.id, data],
    );


    useEffect(() => {
        fetchData();
    }, [fetchData]);


    useEffect(() => {
        if ( trainer.properties !== null && trainer.properties?.addDesc.length) {
            setDescriptions(
                trainer.properties?.addDesc.split('\n')
            )
        }
    }, [trainer.properties]);


    const renderDescription = (descriptions: Array<string>) => {
        return (
            <>
            {descriptions.length ? descriptions.map((description, index) => (
               <StyledCoachDescription.ParagraphWrapper key={index} dangerouslySetInnerHTML={{__html: description}}>
               </StyledCoachDescription.ParagraphWrapper>
            )) : <p>Нет описания</p>}
            </>

        )
    };


    return (
        <>
            <Breadcrumbs
                location={{
                    ...location,
                    pathname: '/coaches'
                }}
                title='Тренеры'
            />
            {error ? (
                <p>Произошла ошибка при получении информации</p>
            ) : (
                <>
                    <h1>{trainer.name}</h1>
                    <StyledCoachDescription.Wrapper>
                        {trainer.properties !== null ?
                            (<StyledCoachDescription.Image
                                        src={`${process.env.REACT_APP_HOST}/photos/${trainer.properties?.photo}`}
                                />
                            )
                            : null}
                        <StyledCoachDescription.Description>
                            {trainer.properties !== null ? renderDescription(descriptions)  : <p>Нет описания</p>}
                            {(trainer.properties !== null && trainer.properties?.video) ? (
                                <StyledCoachDescription.Content>
                                    <StyledCoachDescription.Subtitle>Видео о тренере:</StyledCoachDescription.Subtitle>
                                    <iframe
                                        title='trainer'
                                        width='100%'
                                        height='315'
                                        src={trainer.properties?.video}
                                    >
                                    </iframe>
                                </StyledCoachDescription.Content>
                            ): null}
                            <SocialsMain>
                                {(trainer.properties !== null && trainer.properties?.instagramRef) ? (
                                    <StyledCoachDescription.Content>
                                        <StyledCoachDescription.Subtitle>Соц.сети:</StyledCoachDescription.Subtitle>
                                        <a rel='nofollow' href={trainer.properties.instagramRef}
                                           title='Instagram'>
                                            <Instagram/>
                                        </a>
                                    </StyledCoachDescription.Content>
                                ): null}
                            </SocialsMain>
                        </StyledCoachDescription.Description>
                    </StyledCoachDescription.Wrapper>
                </>
            )}
        </>
    );
};

export default CoachDescription;
