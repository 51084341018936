import React, {FC, useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {Breadcrumbs, List} from '../components'
import {ArticleType} from '../types';
import useDocumentTitle from '../utils/useDocumentTitle';
import articlesJSON from '../assets/articles.json';
import auctionsJSON from '../assets/auctions.json';

const Description: FC = () => {
    const location = useLocation();
    const {pathname} = location;
    const params = useParams();
    const [article, setArticle] = useState<ArticleType>();
    const [title, setTitle] = useState<string>('');



    useEffect(() => {
        const data = pathname.includes('destinations') ? articlesJSON as Array<ArticleType> :
            auctionsJSON as Array<ArticleType>;

        let _article: ArticleType | undefined = {} as ArticleType;

        const articleURLParam =  pathname.includes('destinations') ? params.trainings : params.auction;

        if ( typeof articleURLParam !== 'undefined') {
            _article = data.find((article) => article.href.includes(articleURLParam));
        }

        if (typeof  _article !== 'undefined') {
            setArticle(_article);
            setTitle(_article.title);
        }

    }, [params.trainings, pathname, params.auction]);

    useDocumentTitle(` ${title} | Искусство бокса`, true);


    const renderItem = (type: string, content: Array<string>) => {
        if (type === 'list') return <List items={content}/>

        return content.map((item, index) => <p key={index}>{item}</p>)
    }

    return (
        <>
            <Breadcrumbs
                location={location}
                title={title}
            />
            <h1>{title}</h1>
            {article ? (
                <div>
                    {article.content.map((item) => renderItem(item.type, item.content))}
                </div>
            ) : (
                <p>Описание не найдено</p>
            )}
        </>
    );
};

export default Description;
