import styled from 'styled-components';
import {MouseEventHandler} from 'react';

type ModalBackgroundProps = {
    onClick: MouseEventHandler
}

const Modal = {
    Background: styled.div<ModalBackgroundProps>`
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1005;
      display: block;
      background-color: rgba(0, 0, 0, 0.45);
      overflow-y: auto;
    `,
    Wrapper: styled.div`
        position: relative;
        width: 600px;
        margin: 0 auto;
        top: 140px;
        padding: 1.3125rem;
        background-color: white;
        border-radius: 0.25rem;
        form {
            margin-bottom: 0;
            width: unset;
        }

       @media (max-width: 640px) {
          width: 90%;
       };
    `,
}

export default Modal
