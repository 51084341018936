import React, {FC} from 'react';
import {ListItem} from '../styles/components';
import {Breadcrumbs} from '../components';
import {useLocation} from 'react-router-dom';
import useDocumentTitle from '../utils/useDocumentTitle';

const PrivacyStatement: FC = () => {
    const location = useLocation();

    useDocumentTitle('Политика конфиденциальности | Искусство бокса', true);

    return (
        <>
            <>
                <Breadcrumbs
                    location={location}
                    title='Политика конфиденциальности'
                />
                <h1>Политика конфиденциальности</h1>
                <h2>Политика конфиденциальности: что такое и зачем она нужна пользователям?</h2>
                <p>
                    Часто ли мы обращаем внимание на политику конфиденциальности? Обычно этот текст пролистывается с той
                    же
                    досадой, что и официальное соглашение перед установкой какой-либо программы. А, между тем, оба этих
                    документа содержат важные сведения.
                </p>
                <p>
                    Касательно политики конфеденциальности: вы же хотите узнать, зачем сайту понадобился ваш телефон,
                    адрес электронной почты, ФИО и другие специфические данные? Немаловажно будет узнать, как сайт
                    гарантирует,
                    что эти сведения не будут доступны посторонним лицам, не попадут в базу спамеров. И в каких случаях,
                    передача данных третьим лицам возможна. Политика конфиденциальности предполагает, что вы всегда
                    можете
                    удалить свои данные с сайта (и это не всегда можно сделать в личном профиле), если вдруг по какой-то
                    причине захотите прекратить пользование ресурсом. Там же будет инструкция, как изменить данные на
                    актуальные.
                </p>
                <p>Исходя из всего вышесказанного, ознакомьтесь, пожалуйста, с политикой конфиденциальности нашего
                    сайта.</p>
                <h2>Политика конфиденциальности и защиты информации</h2>
                <p>Оставляя данные на сайте, Вы соглашаетесь с Политикой конфиденциальности и защиты информации.</p>

                <h3>Защита данных</h3>
                <p>Администрация сайта <b>iskusstvoboksa.beta3.ceteralabs.com</b> (далее Сайт) не может передать или
                    раскрыть
                    информацию, предоставленную пользователем (далее Пользователь) при регистрации и использовании
                    функций сайта третьим лицам, кроме случаев, описанных законодательством страны, на территории
                    которой пользователь ведет свою деятельность.</p>

                <h3>Получение персональной информации</h3>
                <p>Для коммуникации на сайте пользователь обязан внести некоторую персональную информацию, а именно:</p>
                <ul>
                    {
                        ['Имя;', 'Адрес электронной почты;', 'Номер телефона.'].map((item, index) => {
                            return <ListItem key={index} marker={'disc'}>{item}</ListItem>
                        })
                    }

                </ul>

                <p>Для проверки предоставленных данных, сайт оставляет за собой право потребовать доказательства
                    идентичности в онлайн или офлайн режимах.</p>

                <h3>Использование персональной информации</h3>
                <p>Сайт использует личную информацию Пользователя для обслуживания и для улучшения качества
                    предоставляемых
                    услуг. Часть персональной информации может быть предоставлена банку или платежной системе, в случае,
                    если предоставление этой информации обусловлено процедурой перевода средств платежной системе,
                    услугами
                    которой Пользователь желает воспользоваться. Сайт прилагает все усилия для сбережения в сохранности
                    личных данных Пользователя. Личная информация может быть раскрыта в случаях, описанных
                    законодательством, либо когда администрация сочтет подобные действия необходимыми для соблюдения
                    юридической процедуры, судебного распоряжения или легального процесса необходимого для работы
                    Пользователя с Сайтом. В других случаях, ни при каких условиях, информация, которую Пользователь
                    передает Сайту, не будет раскрыта третьим лицам.</p>

                <h3>Коммуникация</h3>
                <p>После того, как Пользователь оставил данные, он получает сообщение, подтверждающее его успешную
                    регистрацию. Пользователь имеет право в любой момент прекратить получение информационных бюллетеней
                    воспользовавшись электронной почтой администрации Сайта.</p>

                <h3>Ссылки</h3>
                <p>На сайте могут содержаться ссылки на другие сайты. Сайт не несет ответственности за содержание,
                    качество
                    и политику безопасности этих сайтов. Данное заявление о конфиденциальности относится только к
                    информации, размещенной непосредственно на сайте.</p>

                <h3>Безопасность</h3>
                <p>Сайт обеспечивает безопасность учетной записи Пользователя от несанкционированного доступа.</p>

                <h3>Уведомления об изменениях</h3>
                <p>Сайт оставляет за собой право вносить изменения в Политику конфиденциальности без дополнительных
                    уведомлений. Нововведения вступают в силу с момента их опубликования. Пользователи могут отслеживать
                    изменения в Политике конфиденциальности самостоятельно.</p>

            </>
        </>
    );
};

export default PrivacyStatement;
