import styled from 'styled-components';

export const StyledCoachDescription = {
    Title: styled.h1`
      text-align: center;
    `,
    Subtitle: styled.h4`
      margin-bottom: 0.5rem;
    `,
    Wrapper: styled.div`
      display: flex;
      margin: 0 0 3rem;
      padding: 0;

      @media screen  and (max-width: 768px) {
        flex-direction: column;
      }
    `,
    Content: styled.div`
      display: flex;
      flex-direction: column;
      margin: 0 0 3rem;
      padding: 0;
    `,
    Image: styled.img`
      margin-right: 1.25rem;
      height: 50vh;
      object-fit: cover;

      @media screen  and (max-width: 768px) {
        margin: 0;
      }
    `,
    ImageContainer: styled.div`
      width: 400px;
      height: 500px;
      margin-right: 1rem;
      
      @media screen  and (max-width: 768px) {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    `,
    Description: styled.div`
      display: flex;
      flex-direction: column;
    `,
    ParagraphWrapper: styled.div`
      display: flex;
      flex-direction: column;
    `
}
