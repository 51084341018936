import {FC, useRef} from 'react';
import {Button, StyledModalDialog} from '../styles/components';


type ModalDialogPropsType = {
    title: string;
    text: string;
    confirmButtonText: string;
    cancelButtonText: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ModalDialog:FC<ModalDialogPropsType> = ({
    title,
    text,
    confirmButtonText,
    cancelButtonText,
    onConfirm,
    onCancel,
}) => {

    const bgRef = useRef(null);

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        if (event.target === bgRef.current) onCancel();
    }

    return (
        <StyledModalDialog.Background ref={bgRef} onClick={handleClose}>
            <StyledModalDialog.Wrapper>
                <StyledModalDialog.Title>{title}</StyledModalDialog.Title>
                <StyledModalDialog.Text>{text}</StyledModalDialog.Text>
                <StyledModalDialog.ButtonWrapper>
                    <Button
                        onClick={onConfirm}
                        transparent={false}
                        colored='#DF0F0F'
                    >{confirmButtonText}
                    </Button>
                    <Button
                        onClick={onCancel}
                        transparent={false}
                        colored='#DF0F0F'
                    >{cancelButtonText}
                    </Button>
                </StyledModalDialog.ButtonWrapper>
            </StyledModalDialog.Wrapper>
        </StyledModalDialog.Background>
    );
};

export default ModalDialog;
