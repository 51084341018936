import React, {FC} from 'react';
import RecordsLoader from '../ProfileRecords/RecordsLoader';
import Records from '../ProfileRecords/Records';
import StyleProfile from '../../styles/StyleProfile';
import {useGetRecordsQuery} from '../../services/RecordsService';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/store';

const ProfileRecords: FC = () => {
    const {token} = useSelector((state: RootState) => state.auth)
    
    const {data: activeRecordsInfo, isLoading: isLoadingActiveRecords} = useGetRecordsQuery({id: 138, token, inpast: 0})
    const {data: pastRecordsInfo, isLoading: isLoadingPastRecords} = useGetRecordsQuery({id: 138, token, inpast: 1})
    
    return (
        <StyleProfile.Records textAlign={isLoadingActiveRecords || isLoadingPastRecords}>
            {activeRecordsInfo?.records.length === 0 && pastRecordsInfo?.records.length === 0 &&
                <h2>Здесь будут отображаться ваши тренировки</h2>}

            {isLoadingActiveRecords
                ? <RecordsLoader/>
                : activeRecordsInfo && activeRecordsInfo.records.length > 0 &&
                <Records title={'Активные'} records={activeRecordsInfo.records}/>}

            {isLoadingPastRecords
                ? <RecordsLoader marginTop={20}/>
                : pastRecordsInfo && pastRecordsInfo.records.length > 0 &&
                <Records title={'Прошедшие'} records={pastRecordsInfo.records}/>}
        </StyleProfile.Records>
    );
};

export default ProfileRecords;
