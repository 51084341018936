import styled from 'styled-components';

type StyledPopupProps = {
    type: string
    open: boolean
}

export const StyledPopup = styled.div<StyledPopupProps>`
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: ${p => p.type === 'info' ? 'inherit' : (p.type === 'error' ? '#ffdedb' : '#ddf5ea')};
    border: 1px solid ${
        p => p.type === 'info' ? (p.open ? '#dadde0' : 'inherit') : (p.type === 'error' ? '#ff8d82' : '#88dcb6')
    };
    padding: 15px;
    border-radius: 3px;
    z-index: ${p => p.open ? 3 : -1};
    max-width: 300px; 

    @media (max-width: 640px) {
      max-width: 150px;
    }
`
